import { gql } from '@apollo/client'

export const PHOTO_ATTRIBUTES = gql`
  fragment photoAttributes on Photo {
    id
    submitted
    submittedDate
    isShortListed
    covidRelated
    summarySent
    updatedAt
    hasPeople
    url
    publishable
    originalUrl
    downloadUrl
    filename
    title
    caption
    editedCaption
    editedTitle
    published
    publishUpdatedAt
    adminApproved
    originalCaption
    sentStudentSummary
    originalTitle
    attribution
    altText
    id
    rawFileUrl
    rawFileName
    rawFileSize
    originalFileSize
    cdnOriginalUrl
    sizedUrl(screenWidth: 1000)
    mrAdminNotes
    totalMrRequired
    readyToPublish
    project {
      id
      submitted
      submittedUpdatedAt
      reviewed
      student {
        name
        mobileNumber
        email
        dob
        id
      }
      submittedUpdatedAt
      cohort {
        id
        endDate
        reviewed
        isReviewSend
        summarySent
        curatorReviews {
          id
          reviewerId
        }
        facilitator {
          id
          email
        }
        teacher {
          id
          email
        }
        curators {
          id
          email
        }
      }
      student {
        id
        dob
        name
        mobileNumber
        email
      }
    }
    useAgreements {
      id
      linkToFile
      signed
      sentTo
      signeeFirstName
      signeeLastName
      sentVia
      uaStatus
      adminDecision
      adminRejectionNote
      adminDecisionOn
      signedon
      relatedUaUuid
      wantsUpdates
      email
      phoneNumber
      createdAt
      uuid
    }
    exifData {
      size
      width
      height
      exposureProgram
      meteringMode
      iso
      lensModel
      focalLength
      cameraModel
      captureDate
      aperture
      shutterSpeedValue
      fileType
    }
    modelReleases {
      id
      uuid
      sentTo
      phoneNumber
      email
      sentViaSms
      sentViaEmail
      createdAt
      firstName
      lastName
      modelFirstName
      modelLastName
      linkToFile
      signed
      sentVia
      isMinor
      isDeceased
      signeeFirstName
      signeeLastName
      mrStatus
      adminDecision
      relatedToMr
      signedOn
      adminDecisionOn
      adminRejectionNote
    }
    awards {
      id
      awardClass
      nominatorInitials
      nominatorUserId
      nominatorName
      nominatorType
      isEdited
      editedOn
    }
    photoComments {
      body
      id
      commenterInitials
      commenterUserId
      commenterName
      createdAt
    }
  }
`
