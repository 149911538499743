import React, { useEffect } from 'react'

const Jotform = ({ photoId, curatorName, curatorId }) => {
  const baseUrl = typeof window !== 'undefined' ? window.location.origin : ''

  useEffect(() => {
    const handlerScript = document.createElement('script')
    handlerScript.src = 'https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'
    handlerScript.async = true
    document.body.appendChild(handlerScript)

    const formScript = document.createElement('script')
    formScript.src =
      'window.jotformEmbedHandler("iframe[id=\'JotFormIFrame-250296292520051\']", "<https://form.jotform.com/>")'
    formScript.async = true
    document.body.appendChild(formScript)

    return () => {
      document.body.removeChild(handlerScript)
      document.body.removeChild(formScript)
    }
  }, [])

  return (
    <iframe
      id='JotFormIFrame-250296292520051'
      title='wrkxfmly Photograph Scorecard'
      onLoad={() => window.parent.scrollTo(0, 0)}
      allow='geolocation; microphone; camera; fullscreen'
      src={`https://form.jotform.com/250296292520051?photoUrl=${baseUrl +
        `/photo-detail/${photoId}`}&curatorName=${curatorName}&curatorId=${curatorId}`}
      style={{
        minWidth: '100%',
        maxWidth: '100%',
        height: '3572px',
        border: '0px',
        overflow: 'hidden'
      }}
    />
  )
}

export default Jotform
