import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { Award, Shield, Slash } from 'react-feather'
import EditAwardModal from '../../../molecules/EditAwardModal'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { observer, inject } from 'mobx-react'
import Jotform from '../../../molecules/JotForm'

const AwardSection = props => {
  const { photo, publicView, role, refetchPhoto, userStore } = props
  const { isReviewSend, curators, curatorReviews, summarySent, reviewed } = photo.project.cohort
  const [showEditAwardModal, setShowEditAwardModal] = useState(false)
  const [editAward, setEditAward] = useState(null)

  const isClassInCurotorialReview =
    isReviewSend &&
    (!curatorReviews.count || curators.count !== curatorReviews.count) &&
    !reviewed &&
    !summarySent

  const getDesignedAward = award => {
    return (
      <Grid container key={award.id} sx={{ mb: { xs: 1 } }}>
        <Grid item xs={0} sx={{ pr: { xs: 1 } }}>
          {award.awardClass === 'Award of Excellence' ? (
            <Award size={19} />
          ) : award.awardClass === 'Honorable Mention' ? (
            <Shield size={19} />
          ) : (
            <Slash size={19} />
          )}
        </Grid>
        <Grid item display={{ xs: 'block', sm: 'none' }}>
          <Typography variant='status' component={'p'}>
            {`${award.nominatorName} (${
              award.nominatorType === 'AppAdmin' ? 'Administrator' : 'Curator'
            }) `}
          </Typography>
        </Grid>
        <Grid item display={{ xs: 'none', sm: 'block' }}>
          <Typography variant='status' component={'p'}>
            {`${award.awardClass} Awarded by: ${award.nominatorName} (${
              award.nominatorType === 'AppAdmin' ? 'Administrator' : 'Curator'
            }) `}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          {award.nominatorType === 'Curator' && role === 'appadmin' ? (
            <Typography
              variant='link'
              onClick={() => {
                setShowEditAwardModal(true)
                setEditAward(award)
              }}
              sx={{ pl: { sm: 1 } }}
            >
              Edit Award
            </Typography>
          ) : null}
        </Grid>
        {award.isEdited ? (
          <Grid
            item
            xs={12}
            sx={{
              mt: { xs: award.nominatorType === 'Curator' && role === 'appadmin' ? 0.6 : 1, sm: 1 }
            }}
          >
            <Typography variant='note' component={'p'}>
              Award type edited by <i>wrkxfmly</i> administrator on{' '}
              {moment(award.editedOn, 'YYYY-MM-DD').format('MMMM D, YYYY')}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  const getFinalAward = awards => {
    let aoeAwardsCount = awards.filter(award => award.awardClass === 'Award of Excellence').length
    let hmAwardCount = awards.filter(award => award.awardClass === 'Honorable Mention').length
    return aoeAwardsCount > hmAwardCount
      ? { title: 'Award of Excellence', icon: <Award size={19} /> }
      : hmAwardCount > aoeAwardsCount
      ? { title: 'Honorable Mention', icon: <Shield size={19} /> }
      : aoeAwardsCount === hmAwardCount
      ? { title: 'Award of Excellence', icon: <Award size={19} /> }
      : { title: '', icon: '' }
  }

  const getFinalAwardContent = awards => {
    const finalAward = getFinalAward(awards)
    return (
      <Typography variant='status' component={'p'} sx={{ mt: { xs: 1 } }}>
        {finalAward.icon}
        {' Final Award for This Photograph: '}
        {finalAward.title}
      </Typography>
    )
  }

  return (
    <>
      {!publicView && photo && ['appadmin', 'curator'].includes(role) ? (
        <Box
          component={'div'}
          className='photo-detail__award-notification'
          sx={{ p: { xs: '12px 0 28px', lg: '12px 0 44px' } }}
        >
          {isClassInCurotorialReview ? (
            <Accordion
              defaultExpanded={['curator'].includes(role) ? true : false}
              sx={{
                boxShadow: 'none',
                '.MuiAccordionSummary-root': {
                  px: 0
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon size={16} />}
                aria-controls='final-award'
                id='final-award'
                sx={{ alignItems: 'baseline' }}
              >
                <Box>
                  <Typography variant='h2' className='strong'>
                    Awards
                  </Typography>
                  <Box component={'div'} sx={{ mt: { xs: 1 } }}>
                    <Typography variant='dataLabel' component={'p'} sx={{ mt: { xs: 1 } }}>
                      Fill out the form below to calculate the award type for this photograph, then:
                    </Typography>
                    <Typography variant='dataLabel' component={'div'} sx={{ mt: { xs: 1 } }}>
                      <ol>
                        <li>Select the relevant award type at the top of the screen.</li>
                        <li>Submit the form so that the scores are saved.</li>
                      </ol>
                    </Typography>
                    <Typography variant='dataLabel' component={'p'} sx={{ mt: { xs: 1 } }}>
                      The <i>wrkxfmly</i> administrator will review the final award and correct any
                      discrepancies before sending awards to students
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0, px: 0 }}>
                <Jotform
                  photoId={photo.id}
                  curatorName={userStore.firstName}
                  curatorId={userStore.id}
                />
              </AccordionDetails>
            </Accordion>
          ) : ['appadmin', 'curator'].includes(role) && photo.awards && photo.awards.length > 0 ? (
            <Accordion
              defaultExpanded={['curator'].includes(role) ? true : false}
              sx={{
                boxShadow: 'none',
                '.MuiAccordionSummary-root': {
                  px: 0
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon size={16} />}
                aria-controls='final-award'
                id='final-award'
                sx={{ alignItems: 'baseline' }}
              >
                <Box>
                  <Typography variant='h2' className='strong'>
                    Awards
                  </Typography>
                  {getFinalAwardContent(photo.awards)}
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0, px: 0 }}>
                <Box sx={{ pt: 0.5 }}>
                  <Typography variant='paragraph' component={'p'}>
                    The final award for this photograph is the type awarded by the greatest number
                    of people.
                  </Typography>
                  {['appadmin'].includes(role) ? (
                    <Typography variant='paragraph' component={'p'}>
                      If a tie-break is needed after curatorial review, you can break the tie by
                      adding your own award in the header bar.
                    </Typography>
                  ) : (
                    <Typography variant='paragraph' component={'p'}>
                      If a tie-break is needed after curatorial review, the <i>wrkxfmly</i>{' '}
                      administrator can break the tie.
                    </Typography>
                  )}
                </Box>
                <Box component={'div'} sx={{ mt: { xs: 4, sm: 3 }, mb: { xs: 2.5 } }}>
                  {photo.awards.map(award => (
                    <Fragment key={award.id}>{getDesignedAward(award)}</Fragment>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ) : (
            <>
              <Typography variant='h2' className='strong'>
                Awards
              </Typography>
              <Typography variant='paragraph' component={'p'}>
                This photograph has not received any award nominations.
              </Typography>
            </>
          )}
          <EditAwardModal
            show={showEditAwardModal}
            onHide={() => {
              setShowEditAwardModal(false)
              setEditAward(null)
            }}
            awardId={editAward ? editAward.id : ''}
            photoId={photo.id}
            nominatorName={editAward ? editAward.nominatorName : ''}
            awardClass={editAward ? editAward.awardClass : ''}
            refetchPhoto={refetchPhoto}
          />
        </Box>
      ) : null}
    </>
  )
}
export default inject('userStore')(observer(AwardSection))
